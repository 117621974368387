import { AppConfig, Menu } from "./app/interfaces/app-config";

export const globalConfig: AppConfig = {
    menu: [
        Menu.create({
            id: 2,
            path: '/ticket',
            title: 'Comprar',
        }),
        Menu.create({
            id: 3,
            path: '/tickets-saved',
            title: 'Mis Compras',
        }),
    ],
    mobileMenu: [
        Menu.create({
            id: 1,
            path: '/ticket',
            title: 'Comprar',
            icon: '/assets/icons/home_white.svg',
            iconType: 'img'
        }),
        Menu.create({
            id: 3,
            path: '/tickets-saved',
            title: 'Mis compras',
            icon: '/assets/icons/ticket_white.svg',
            iconType: 'img'
        }),
    ],
    footerMenu: [
        {
            id: 1,
            title: 'Páginas',
            menu: [
                Menu.create({
                    id: 1,
                    path: '/',
                    title: 'Términos y Condiciones',
                    custom: true
                }),
            ]
        },
        {
            id: 2,
            title: 'Contacto',
            menu: [
                Menu.create({
                    id: 8,
                    path: 'https://www.facebook.com/nonstopthemadness/?locale=es_LA',
                    title: 'Todotix',
                    external: true,
                    target: '_blank',
                    icon: '/assets/icons/facebook.svg',
                    iconType: 'img'
                }),
                Menu.create({
                    id: 9,
                    path: 'https://www.instagram.com/nonstopthemadness/',
                    title: 'Todotix',
                    external: true,
                    target: '_blank',
                    icon: '/assets/icons/instagram.svg',
                    iconType: 'img'
                }),
                Menu.create({
                    id: 10,
                    path: 'https://www.youtube.com/@nonstopthemadness4143',
                    title: 'Todotix',
                    external: true,
                    target: '_blank',
                    icon: '/assets/icons/youtube.svg',
                    iconType: 'img'
                }),
                Menu.create({
                    id: 11,
                    path: 'https://www.tiktok.com/@nonstopthemadness?lang=es',
                    title: 'Todotix',
                    external: true,
                    target: '_blank',
                    icon: '/assets/icons/tiktok.svg',
                    iconType: 'img'
                }),

            ]
        }
    ],
    pages: {
        login: {
            title: 'Inicia sesión en tu cuenta',
            subtitle: '¡Bienvenido de vuelta! Por favor, ingresa tus datos.',
            form: {
                email: {
                    label: 'Correo electrónico',
                    placeholder: 'Ingresa tu correo electrónico'
                },
                password: {
                    label: 'Contraseña',
                    placeholder: 'Ingresa tu contraseña'
                },
                rememberMe: {
                    label: 'Recordarme'
                },
            },
            forgotPassword: '¿Olvidaste tu contraseña?',
            submit: 'Iniciar sesión',
            nextAuth: {
                question: '¿No tienes una cuenta?',
                action: 'Registrate'
            }
        },
        register: {
            title: 'Crea tu cuenta',
            subtitle: 'Por favor, ingresa los datos requeridos',
            form: {
                name: {
                    label: 'Nombre completo',
                    placeholder: 'Ingresa tu nombre completo'
                },
                email: {
                    label: 'Correo electrónico',
                    placeholder: 'Ingresa tu correo electrónico'
                },
                phone: {
                    label: 'Teléfono celular',
                    placeholder: 'Ingresa tu celular'
                },
                city: {
                    label: 'Ciudad',
                    placeholder: 'Selecciona tu ciudad'
                },
                credential: {
                    label: 'Número de identificación',
                    placeholder: 'Ingresa tu identificación'
                },
                password: {
                    label: 'Contraseña',
                    placeholder: 'Ingresa tu contraseña'
                },
                repeatPassword: {
                    label: 'Repite la contraseña',
                    placeholder: 'Repide tu contraseña'
                },
            },
            submit: 'Registrarse',
            nextAuth: {
                question: '¿Ya tienes una cuenta?',
                action: 'Inicia sesión'
            }
        },
    },
    validations: {
        required: "El campo \"{label}\" es requerido.",
        number: "El campo \"{label}\" debe contener solo números.",
        minlength: "El campo \"{label}\" debe tener al menos \"{requiredLength}\" caracteres.",
        maxlength: "El campo \"{label}\" debe tener un máximo de \"{requiredLength}\" caracteres.",
        min: "El campo \"{label}\" debe contener al menos \"{min}\" elementos.",
        max: "El campo \"{label}\" debe tener un máximo de \"{max}\" elementos.",
        email: "El campo \"{label}\" debe ser un email válido.",
        default: "El campo \"{label}\" es inválido.",
    },
};
