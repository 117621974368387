import { StorageResultApiService } from './storage-result-api.service';
import { Injectable } from '@angular/core';

import { SQLiteService } from './sqlite.service';

@Injectable()
export class InitializeStorageService {
    isAppInit: boolean = false;
    platform!: string;

    constructor(
        private sqliteService: SQLiteService,
        private storageService: StorageResultApiService,
    ) {}

    async initializeApp() {
        await this.sqliteService.initializePlugin().then(async (ret) => {
            this.platform = this.sqliteService.platform;
            try {
                if( this.sqliteService.platform === 'web') {
                    await this.sqliteService.initWebStore();
                }
                // Initialize the mydatadb database
                const DB_DATA = 'mydatadb'
                await this.storageService.initializeDatabase(DB_DATA);
                // Here Initialize MOCK_DATA if required

                // Initialize whatever database and/or MOCK_DATA you like

                if( this.sqliteService.platform === 'web') {
                    await this.sqliteService.saveToStore(DB_DATA);
                }

                this.isAppInit = true;
            } catch (error) {
                console.log(`initializeAppError: ${error}`);
            }
        });
    }
}