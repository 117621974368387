import { mainAppConfigProviders } from './../../../../src/app.config';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { PreloadAllModules, provideRouter, withPreloading } from '@angular/router';
import { routes } from './app.routes';
import { projectConfig, projectEncryptData } from './project.config';
import { environment } from '../environments/environment';

declare global {
  var selfEnv: string;
  var selfPc: string;
}
window['selfEnv'] = projectEncryptData(environment);
window['selfPc'] = projectEncryptData(projectConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    ...mainAppConfigProviders,
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withPreloading(PreloadAllModules)),
  ],
};
