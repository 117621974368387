import { CYFooterMenuComponent } from './../../../../src/app/layout/components/footer-menu/footer-menu.component';
import { PageService } from './../../../../src/app/services/page.service';
import { CYDrawerComponent } from './../../../../src/app/layout/components/drawer/drawer.component';
import { Component, ViewChild } from '@angular/core';
import { IonApp, IonRouterOutlet, Platform, ToastController } from '@ionic/angular/standalone';
import { environment } from '../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { App } from '@capacitor/app';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [
    IonApp,
    IonRouterOutlet,
    CYDrawerComponent,
    CommonModule,
    CYFooterMenuComponent,
  ],
})
export class AppComponent {

  title = environment.title;

  @ViewChild(IonRouterOutlet) outlet?: IonRouterOutlet;

  canExitApp: boolean = false;

  activeMenu?: string;

  constructor(
    private metaTitle: Title,
    private meta: Meta,
    private platform: Platform,
    private toastCtrl: ToastController,
    public pageService: PageService,
  ) {
    // this.pageService.hasMenu$.subscribe(hasMenu => console.log(hasMenu));
    this.pageService.activeMenu$.subscribe(activeMenu => this.activeMenu = activeMenu);
    this.initializeApp();
  }

  initializeApp() {
    this.metaTitle.setTitle(`${environment.title} | ${environment.subtitle}`);
    this.meta.addTags([
      { name: 'description', content: `${environment.description}.` },
      { name: 'keywords', content: environment.keywords },
    ]);

    let exitTimeout: any = null;

    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (!this.outlet?.canGoBack()) {
        if (this.canExitApp) {
          App.exitApp();
        } else {
          this.toastCtrl.create({
            message: 'Si está seguro de salir, presione atrás otra vez',
            duration: 3000 // 3 segundos
          }).then(toast => toast.present());

          this.canExitApp = true;

          if (exitTimeout) {
            clearTimeout(exitTimeout);
          }

          exitTimeout = setTimeout(() => {
            this.canExitApp = false;
          }, 5000);
        }
      }
    });
  }

}
