import { ENV } from "../../../../src/app/interfaces/interfaces";

export const environment:ENV = {
    production: false,
    apiUrl: 'https://colocolo.gateway.test.solunes.com/',
    type: 'ucatolica',
    title: 'Club Deportivo Universidad Católica',
    subtitle: 'Web',
    description: `Sitio web "Club Deportivo Universidad Católica".`,
    keywords: 'club, ucatolica, universidad catolica chile, club catolica chile, chile, universidad catolica',
    yearProyect: '2024',
    imgLogo: '/assets/img/logo.png',
    imgLogoSVG: '/assets/img/logo.svg',
    imgLogoBig: '/assets/img/logo-big.png',
    templateType: 'template-1',
    theme: 'light',
    changeTheme: false,

    countryId: 1, // Chile

    dashboard: false,
    disclaimer: true,
    header: true,
    sidebar: true,
    footer: true,
    preFooter: true,

    authType: 'auth-1',
    authGoogle: true,
    authFacebook: false,
    authApple: false,
    authGithub: false,
    textToLogin: '¿Ya tienes una cuenta?',
    textToRegister: '¿Todavía no eres miembro?',

    authRedirect: 'home',

    processCartType: 'type-1',
    processCartTitle: 'Conviértete en Socio Centenario',

    multiplePayments: true,
    multipleProfiles: true,

    loaderType: 1,
    loaderDefaultText: 'Cargando...',

    keyEncript: '3fa85f64c2f9e0c53fa85f64c2f9e0c5',
    ivEncript: '3fa85f64c2f9e0c5',
};
