import { ProjectConfigService } from './app/services/project-config.service';
import { InitializeStorageService } from './app/services/sqlite/initialize-storage.service';
import { CustomToastComponent } from './app/components/custom-toast/custom-toast.component';
import { UserModel } from './app/services/user-model';
import { TokenInterceptorService } from './app/services/interceptor.service';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from "@angular/core";
import { AuthService } from "./app/services/auth.service";
import { StorageService } from "./app/services/storage.service";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi,  } from "@angular/common/http";
import { DatePipe } from '@angular/common';
import { LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientProvider } from './app/services/http-client';
import { provideLottieOptions } from 'ngx-lottie';
import { ToastrModule } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { defineCustomElements as jeepSqlite } from 'jeep-sqlite/loader';
import { register } from 'swiper/element/bundle';
register();
import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window);
import { Capacitor } from '@capacitor/core';
import { SQLiteService } from './app/services/sqlite/sqlite.service';
import { StorageResultApiService } from './app/services/sqlite/storage-result-api.service';
import { DbnameVersionService } from './app/services/sqlite/dbname.version.service';

function appInitializer(
    storageService: StorageService, 
    projectConfigService: ProjectConfigService, 
    authService: AuthService, 
    init: InitializeStorageService
) {
    return async () => {
        await storageService.init();
        await projectConfigService.loadConfig();
        await authService.getUserByToken();
        await authService.getGeneralData();
        await init.initializeApp();
    }
}

const platform = Capacitor.getPlatform();
if(platform === "web") {
    jeepSqlite(window);

    window.addEventListener('DOMContentLoaded', async () => {
        const jeepEl = document.createElement("jeep-sqlite");
        document.body.appendChild(jeepEl);
        await customElements.whenDefined('jeep-sqlite');
        jeepEl.autoSave = true;
    });
}

export const mainAppConfigProviders: ApplicationConfig['providers'] = [
    SQLiteService,
    InitializeStorageService,
    StorageResultApiService,
    DbnameVersionService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [StorageService, ProjectConfigService, AuthService, InitializeStorageService] },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    DatePipe,
    importProvidersFrom(IonicStorageModule.forRoot()),
    importProvidersFrom(HttpClientProvider),
    provideHttpClient(
        withInterceptorsFromDi()
    ),
    importProvidersFrom(AuthService),
    importProvidersFrom(UserModel),
    provideLottieOptions({
        player: () => import('lottie-web'),
    }),
    importProvidersFrom(ToastrModule.forRoot({
        toastComponent: CustomToastComponent,
        newestOnTop: false,
        preventDuplicates: true
    })),
    provideAnimations(),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
];