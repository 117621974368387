import { AuthUserModel } from './../../../interfaces/interfaces';
import { AuthService } from './../../../services/auth.service';
import { IonButtonCapYeiComponent } from './../../../components/ion-button/ion-button.component';
import { XtrasService } from './../../../services/xtras.service';
import { IonMenu, IonList, IonMenuToggle, IonIcon, IonLabel, IonFooter, IonToolbar, IonAvatar, IonItem, NavController, IonPopover } from '@ionic/angular/standalone';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { addIcons } from 'ionicons';
import { chevronBackOutline, caretForwardOutline, logInOutline, createOutline, ellipsisVerticalOutline, playOutline, logOutOutline, homeOutline } from 'ionicons/icons';
import { CYHeaderComponent } from '../header/header.component';
import { getEnvironment } from '../../../../../src/app/environment/environment';
import { CYContentComponent } from '../content/content.component';


@Component({
    selector: 'cy-drawer',
    templateUrl: './drawer.component.html',
    styles: [
        `:host { display: contents }`
    ],
    standalone: true,
    imports: [
        IonPopover, 
        IonLabel,
        IonIcon,
        IonList,
        IonMenuToggle,
        IonMenu,
        IonFooter,
        IonToolbar,
        IonAvatar,
        IonItem,
        CYHeaderComponent,
        CYContentComponent,
        IonButtonCapYeiComponent,
        CommonModule,
        RouterModule,
    ]
})
export class CYDrawerComponent {

    env = getEnvironment();

    getUser?: AuthUserModel;

    constructor(
        public xServices: XtrasService,
        private authService: AuthService,
        private navCtrl: NavController,
    ) {
        addIcons({ chevronBackOutline, caretForwardOutline, logInOutline, createOutline, ellipsisVerticalOutline, playOutline, logOutOutline, homeOutline });
        this.authService.user$.subscribe(data => {
            console.table(data);
            this.getUser = data?.user;
            console.log(data?.user);
        });
    }

    async logout() {
        await this.authService.logout();
    }

    toPage(route:string) {
        this.navCtrl.navigateRoot(route);
    }
}