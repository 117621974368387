<ion-menu #menuSidebar contentId="main-content" menuId="principalMenu">
    <cy-content [fullscreen]="true" addClass="no_pad">
        <div class="content__header-menu">
            <cy-header
                [hasBackButton]="false"
                addClass="header__menu"
                [customTitle]="customTitle"
                >
                <ng-template #customTitle>
                    <div class="content__header-menu-logo">
                        <h3><small>Hola, {{ getUser ? (getUser.name ?? '') : '' }} te saluda</small><br><b>{{ env.title }}</b></h3>
                    </div>
                </ng-template>
            </cy-header>
            <img [src]="env.imgLogoBig" [alt]="env.title" class="img__logo">
        </div>
        <br>
        <ion-list lines="none" class="ion-padding content__body-menu" style="background: transparent;">
            <ion-menu-toggle auto-hide="false">
                <ion-item (click)="toPage('/introduction')" lines="none" detail="false">
                    <ion-icon slot="start" name="caret-forward-outline"></ion-icon>
                    <ion-label>Introducción</ion-label>
                </ion-item>
            </ion-menu-toggle>
            <div class="divider__y"></div>
            <ion-menu-toggle auto-hide="false">
                <ion-item (click)="toPage('/home')" lines="none" detail="false">
                    <ion-icon slot="start" name="home-outline"></ion-icon>
                    <ion-label>Home</ion-label>
                </ion-item>
            </ion-menu-toggle>
            <div class="divider__y"></div>
            <ion-menu-toggle auto-hide="false">
                <ion-item (click)="toPage('/matches')" lines="none" detail="false">
                    <i slot="start" class="fal fa-calendar-week"></i>
                    <ion-label>Partidos</ion-label>
                </ion-item>
            </ion-menu-toggle>
            <div class="divider__y"></div>
            <ion-menu-toggle auto-hide="false">
                <ion-item (click)="toPage('/leaderboard')" lines="none" detail="false">
                    <i slot="start" class="fal fa-calendar-week"></i>
                    <ion-label>Tabla de posiciones</ion-label>
                </ion-item>
            </ion-menu-toggle>
            <div class="divider__y"></div>
            <ion-menu-toggle auto-hide="false">
                <ion-item (click)="toPage('/reels')" lines="none" detail="false">
                    <i slot="start" class="fal fa-people"></i>
                    <ion-label>Reels</ion-label>
                </ion-item>
            </ion-menu-toggle>
            <ng-container *ngIf="getUser">
                <div class="divider__y"></div>
                <ion-menu-toggle auto-hide="false">
                    <ion-item (click)="toPage('/play')" lines="none" detail="false">
                        <ion-icon slot="start" name="play-outline"></ion-icon>
                        <ion-label>Play</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <div class="divider__y"></div>
                <ion-menu-toggle auto-hide="false">
                    <ion-item (click)="logout()" lines="none" detail="false">
                        <ion-icon slot="start" name="log-out-outline"></ion-icon>
                        <ion-label>Cerrar sesión</ion-label>
                    </ion-item>
                </ion-menu-toggle>
            </ng-container>
        </ion-list>
    </cy-content>
    <ion-footer class="content__footer-menu">
        <ion-toolbar>
            @if (getUser) {
                @if (getUser.membershipCurrent) {
                    <div class="content__footer-menu-user">
                        <ion-item (click)="undefined">
                            <ion-avatar slot="start">
                                <img #imgProfile [alt]="getUser.membershipCurrent.name" [src]="getUser.membershipCurrent.image ?? '/global/assets/img/avatar.svg'" (error)="imgProfile.srcset = '/global/assets/img/avatar.svg'" />
                            </ion-avatar>
                            <div class="content__detail">
                                <p>{{ getUser.membershipCurrent.name }}</p>
                                <p><small>{{ getUser.membershipCurrent.email }}</small></p>
                            </div>
                            <ion-button-cy (onPress)="$event.stopPropagation(); optionsProfile.event = $event; optionsProfile.present();" slot="end" fill="clear">
                                <ion-icon slot="icon-only" name="ellipsis-vertical-outline"></ion-icon>
                            </ion-button-cy>
                        </ion-item>
                        <ion-popover #optionsProfile side="top" alignment="center" [dismissOnSelect]="true">
                            <ng-template>
                                <ion-button-cy (onPress)="toPage('/profile/edit/{{ getUser.membershipCurrent.id }}');menuSidebar.close()" expand="block" fill="clear">
                                    <i slot="start" class="fa-regular fa-pen-to-square"></i> Editar
                                </ion-button-cy>
                                <ion-button-cy (onPress)="toPage('/profile/create');menuSidebar.close()" expand="block" fill="clear">
                                    <i slot="start" class="fa-sharp fa-regular fa-arrows-rotate"></i>
                                    Crear otro perfil
                                </ion-button-cy>
                            </ng-template>
                        </ion-popover>
                    </div>
                } @else {
                    <div class="noauth__footer ion-justify-content-center">
                        <span></span>
                        <ion-menu-toggle>
                            <a routerLink="/profile/create">
                                <ion-button-cy >
                                    Agregar perfil <i class="fa-light fa-user-plus"></i>
                                </ion-button-cy>
                            </a>
                        </ion-menu-toggle>
                    </div>
                }
            } @else {
                <div class="noauth__footer">
                    <ion-menu-toggle>
                        <a routerLink="/auth/register">
                            <ion-button-cy fill="clear">
                                Registrate <i class="fa-light fa-user-pen"></i>
                            </ion-button-cy>
                        </a>
                    </ion-menu-toggle>
                    <ion-menu-toggle>
                        <a routerLink="/auth/login">
                            <ion-button-cy >
                                Inicia sesión <i class="fa-light fa-right-to-bracket"></i>
                            </ion-button-cy>
                        </a>
                    </ion-menu-toggle>
                </div>
            }
        </ion-toolbar>
    </ion-footer>
</ion-menu>