import { environment } from '../environments/environment';
import { mainRoutes } from './../../../../src/main.routes';
import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: environment.authRedirect,
    pathMatch: 'full',
  },
  ...mainRoutes
];
