import { Routes } from '@angular/router';

export const mainRoutes: Routes = [
    //* Auth routes
    {
        path: 'auth',
        loadChildren: () => import('./app/routes/auth.routes'),
    },
    {
        path: '',
        loadChildren: () => import('./app/routes/app.routes'),
    },
    
    // * Error
    // {
    //     path: '',
    //     component: CleanComponent,
    //     children: [
    //         {
    //             path: '**',
    //             redirectTo: 'error/404'
    //         },
    //         {
    //             path: 'error/:error',
    //             component: NotFoundPage
    //         }
    //     ]
    // }
];
